// todo：删除多余的路由 @yuyan
/*
 * NOTE:
 * 1. 新增/改变路由，需要在增加/检查权限配置
 */
import {
  USER_ROLE,
  LOGIN_PATHS,
  TRADE_PATH,
  YIELD_PATHS,
  STRATEGIES_PATHS,
  VENTURES_PATHS,
  WALLET_PATHS,
  HISTORY_PATHS,
  PARTNER_PATH,
  ASSETS_PATHS,
  POLICY_PATH,
  AGREEMENT_PATH,
  STRUCTURED_PATH,
  FUNDS_PATHS
} from "@aspen/libs";

const {
  ROLE_CUSTOMER,
  ROLE_PARTNER,
  ROLE_MANAGER,
  ROLE_PARTNER_CUSTOMER,
  ROLE_MANAGER_CUSTOMER,
  ROLE_MANAGER_READONLY,
  ROLE_SUB
} = USER_ROLE;

interface IRoleItem {
  exact: boolean;
  showHeader?: boolean; // 是否展示顶部导航, 临时方案，新闻详情不需要登陆可访问，但是也需要展示顶部导航菜单，其他事是needAuthenticated判断的，后续考虑优化 next todo
  needAuthenticated?: boolean; // 是否需要登陆权限, 默认false
  role?: string[]; // 可以访问的角色列表, 不设置或者为空，即不限角色， 只有在needAuthenticate为true的情况下才需要配置role
  titleKey?: string; // 路由的title
  titleDescription?: string; // 路由的title description
}
export type IRole = {
  [propName: string]: IRoleItem;
};

/*
 * desc： 路由/角色/配置
 * 不配置路由权限或者为空，即不限角色role
 * 只有在needAuthenticate为true的情况下才需要配置role
 *
 * 根据域名判断，登陆路由模块是否存在 （域名中存在partner时为代理商CMS登陆, 存在service时为SAS登陆 仅显示登陆login路由 本地调试代理商的话需要partner改为local）
 */

const loginRouteConfig: IRole = {
  [LOGIN_PATHS.LOGIN]: {
    exact: true,
    needAuthenticated: false,
    role: [],
    titleKey: "router.title.login",
    titleDescription: ""
  }
};

const afterLoginRouteConfig: IRole = {
  "/": {
    exact: true,
    needAuthenticated: true,
    role: [ROLE_CUSTOMER]
  },
  [PARTNER_PATH.DASHBOARD]: {
    exact: true,
    role: [ROLE_PARTNER],
    needAuthenticated: true,
    titleKey: "router.title.clients.partner.management",
    titleDescription: ""
  },
  [PARTNER_PATH.CUSTOMER]: {
    exact: true,
    role: [ROLE_PARTNER],
    needAuthenticated: true,
    titleKey: "router.title.clients.partner.management",
    titleDescription: ""
  },
  [PARTNER_PATH.CUSTOMER_DETAIL]: {
    exact: true,
    role: [ROLE_PARTNER],
    needAuthenticated: true,
    titleKey: "router.title.clients.partner.customer.detail",
    titleDescription: ""
  },
  [PARTNER_PATH.PRODUCT]: {
    exact: true,
    role: [ROLE_PARTNER],
    needAuthenticated: true,
    titleKey: "router.title.clients.partner.management",
    titleDescription: ""
  },
  [PARTNER_PATH.OPERATION]: {
    exact: true,
    role: [ROLE_PARTNER],
    needAuthenticated: true,
    titleKey: "router.title.clients.partner.management",
    titleDescription: ""
  },
  [PARTNER_PATH.AUTH_APPLICATION]: {
    exact: true,
    role: [ROLE_PARTNER],
    needAuthenticated: true,
    titleKey: "router.title.clients.partner.auth.application",
    titleDescription: ""
  },
  [TRADE_PATH]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true
  },
  [YIELD_PATHS.YIELD]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.yield",
    titleDescription: ""
  },
  [STRUCTURED_PATH.STRUCTURED]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.structured",
    titleDescription: ""
  },
  [STRUCTURED_PATH.STRUCTURED_DUAL_SUBSCRIBE]: {
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_MANAGER_CUSTOMER, ROLE_MANAGER_READONLY],
    needAuthenticated: true,
    titleKey: "router.title.dualSubscribe.subscribe",
    titleDescription: ""
  },
  [STRUCTURED_PATH.STRUCTURED_ACCUMULATOR_SUBSCRIBE]: {
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_MANAGER_CUSTOMER, ROLE_MANAGER_READONLY],
    needAuthenticated: true,
    titleKey: "router.title.accumuSubscribe.subscribe",
    titleDescription: ""
  },
  [STRUCTURED_PATH.STRUCTURED_SNOWBALL_SUBSCRIBE]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.snowballSubscribe.subscribe",
    titleDescription: ""
  },
  [YIELD_PATHS.YIELD_MY_INVESTMENT_PLAN]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.yield.my.investment.plan",
    titleDescription: ""
  },
  [YIELD_PATHS.YIELD_ADD_INVESTMENT_PLAN]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.yield.add.investment.plan",
    titleDescription: ""
  },
  [YIELD_PATHS.YIELD_CREATE_DCA_PLAN]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.yield.add.investment.plan",
    titleDescription: ""
  },
  [YIELD_PATHS.YIELD_INVESTMENT_HISTORY]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.yield.investment.history",
    titleDescription: ""
  },
  [YIELD_PATHS.YIELD_SUBSCRIBE_FIXED_YIELD]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.yield.subscribe.fixed",
    titleDescription: ""
  },
  [STRATEGIES_PATHS.STRATEGIES]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.strategies",
    titleDescription: ""
  },
  [STRATEGIES_PATHS.STRATEGIES_DETAIL]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.strategies.detail",
    titleDescription: ""
  },
  [FUNDS_PATHS.FUNDS]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.strategies",
    titleDescription: ""
  },
  [FUNDS_PATHS.FUNDS_DETAIL]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.strategies.detail",
    titleDescription: ""
  },
  [VENTURES_PATHS.VENTURES]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.ventures",
    titleDescription: ""
  },
  [VENTURES_PATHS.VENTURES_DETAIL]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.ventures.detail",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.wallet",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET_AUMFEE]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.wallet.aum.fee",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET_VAULT]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.wallet.vault",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET_VAULT_HISTORY]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.wallet.vault.history",
    titleDescription: ""
  },
  [HISTORY_PATHS.HISTORY]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.history",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET_ACCOUNT_HISTORY]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.history.account.detail",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET_VENTURE_UPDATES_DETAIL]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.wallet.venture.updates",
    titleDescription: ""
  },
  [HISTORY_PATHS.HISTORY_APPLICATION_DETAIL]: {
    exact: true,
    role: [ROLE_SUB],
    needAuthenticated: true
  },
  [ASSETS_PATHS.ASSETS]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.assets",
    titleDescription: ""
  },
  [POLICY_PATH]: {
    exact: true,
    needAuthenticated: false,
    role: [],
    titleKey: "router.title.policy",
    titleDescription: ""
  },
  [AGREEMENT_PATH]: {
    exact: true,
    needAuthenticated: false,
    role: [],
    titleKey: "router.title.agreement",
    titleDescription: ""
  }
};

export const routeConfig = Object.assign(loginRouteConfig, afterLoginRouteConfig);
